---
command: shutdown
alias: [exit]
help: power-off the machine
---

[](sleep:50)
Wait ...
[](sleep:1000)
[](system:clear)
[](sleep:1000)
[](system:shutdown)
