---
command: ls
help: list directory content
---

[](ui:animate)
There is nothing.  
[](sleep:500)
[](ui:animate)
Only warm, primordial blackness.  
[](sleep:500)
