---
command: matrix
alias: [neo]
help: enter the matrix
---

[#6CA391](css:color)
[bold](css:font-weight)
[30px](css:font-size)
[20px](css:margin-top)
[](system:clear)
[](sleep:500)

[](ui:animate)
Wake up, [Neo](const:args)...
[](sleep:2000)
[](system:clear)

[](ui:animate)
The Matrix has you...
[](sleep:2000)
[](system:clear)

[](ui:animate)
Follow the white rabbit.
[](sleep:2000)
[](system:clear)

[](sleep:100)
Knock, knock, [Neo](const:args).
[](sleep:2000)
[](system:clear)
[](sleep:500)
[](system:clear)
