---
command: whoami
help: information about me
---

[](sleep:500)
Adam Zielonka

[](sleep:700)
**[EMAIL]**  
[code.unstitch760@passfwd.com](mailto:code.unstitch760@passfwd.com)

**[REPO]**  
[github.com/adam-zielonka](https://github.com/adam-zielonka)   

**[YouTube]**  
[Code in the Machine](https://www.youtube.com/channel/UC9ndsm1I60DjsIqEh1XITRQ)

**[PCz Trening]**  
[Frontend tutorial](https://adam-zielonka.github.io/frontend-tutorial/)

**[APPS]**  
adam-zielonka.github.io: [Source](https://github.com/adam-zielonka/adam-zielonka.github.io), [Run](https://adam-zielonka.github.io/)  
Questions: [Source](https://github.com/adam-zielonka/questions), [Run](https://adam-zielonka.github.io/questions/)  
Server Status: [Source](https://github.com/adam-zielonka/server-status), [Demo](https://adam-zielonka.github.io/server-status/)  
Unit Converter: [Source](https://github.com/adam-zielonka/unit-converter)  
paintAZ: [Source](https://github.com/adam-zielonka/paintaz)  
mobx-example: [Source](https://github.com/adam-zielonka/mobx-example), [Demo](https://adam-zielonka.github.io/mobx-example/)  
svelte-example: [Source](https://github.com/adam-zielonka/svelte-example), [Demo](https://adam-zielonka.github.io/svelte-example/)  
tiles: [Source](https://github.com/adam-zielonka/tiles), [Run](https://adam-zielonka.github.io/tiles/)  

**[GAMES]**  
Deska: [Source](https://github.com/adam-zielonka/deska), [Play](https://adam-zielonka.github.io/deska/)  
Saper: [Source](https://github.com/adam-zielonka/saper), [Play](https://adam-zielonka.github.io/saper/)  
Tetris: [Source](https://github.com/adam-zielonka/tetris)  
Ships: [Source](https://github.com/adam-zielonka/statki), [Play](https://adam-zielonka.github.io/statki/)  
GameOfLife: [Source](https://github.com/adam-zielonka/game-of-life), [Play](https://adam-zielonka.github.io/game-of-life/)  
Pan: [Source](https://github.com/adam-zielonka/pan), [Play](https://adam-zielonka.github.io/pan/)  
Chińczyk: [Source](https://github.com/adam-zielonka/chinczyk), [Play](https://adam-zielonka.github.io/chinczyk/)  
[](sleep:400)
